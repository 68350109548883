import styled, { css } from 'styled-components';
import breakpoints from '@/styles/settings/breakpoints';
import { TickList, BulletList } from '@/components/atoms/List/index.styled';
import { gap } from '@/styles/settings/spacing';
import { above, below, hover } from '@/styles/tools/media';
import aspectRatio from '@/styles/tools/aspectRatio';
import {
	xsBodyBook,
	smBodyLight,
	smBodyBook,
	smBodyRegular,
} from '../../atoms/Typography/index.styled';

interface CardProps {
	hideImageOnMobile?: boolean
}

interface ListProps {
	hideListOnMobile?: boolean
}

const Card__Wrapper = styled.div`

display: flex;
flex-direction: column;
height: 100%;
transition: all .4s ease-in-out;
text-decoration: none;
color: inherit;

${hover(
	() => css`
	&:hover {
			box-shadow: rgba(0, 0, 0, 0.12) 0px 19px 50px;

transform: translate3d(0px, -15px, 0px);
		}
	`
)}
`;
const Card = styled.article<CardProps>`
	display: flex;
	flex-direction: column;
	height: 100%;
	background-color: var(--white);



	& > picture {
		padding-top: ${aspectRatio('2:1')};
	}



	${props =>
		props.hideImageOnMobile &&
		css`
			& > picture {
				${below(
					breakpoints[960],
					() => css`
						display: none;
					`
				)}
			}
		`}


`;

const Card__Content = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	padding: ${gap[24]} ${gap[16]} ${gap[32]} ${gap[16]};
	border: 1px solid var(--primaryAccent);
    // border-top: none;

	a {
		align-self: flex-start;
	}

	${above(
		breakpoints[960],
		() => css`
			padding: ${gap[32]} ${gap[24]} ${gap[48]} ${gap[24]};
		`
	)}
`;

const Card__Title = styled(smBodyRegular)`
	color: var(--noir);
`;

const Card__SubText = styled(xsBodyBook)`
	margin-top: ${gap[4]};

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${gap[8]};
		`
	)}
`;

const Card__Copy = styled(smBodyLight)`
	margin-top: ${gap[16]};
	flex: 1;

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${gap[24]};
		`
	)}
`;

const Card__Location = styled(xsBodyBook)`
	display: grid;
	grid-template-columns: 16px 1fr;
	grid-column-gap: ${gap[4]};
	align-items: center;
	margin-top: ${gap[4]};
	text-transform: capitalize;

	svg {
		width: 100%;
		height: auto;
	}

	svg > path {
		fill: var(--primaryAccent);
	}

	${above(
		breakpoints[960],
		() => css`
			grid-template-columns: 20px 1fr;
			margin-top: ${gap[8]};
		`
	)}
`;

const Card__List = styled(TickList)<ListProps>`
	flex-grow: 1;
	margin-top: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${gap[32]};
		`
	)}

	${props =>
		props.hideListOnMobile &&
		css`
			${below(
				breakpoints[960],
				() => css`
					display: none;
				`
			)}
		`}
`;

const Card__ListItem = styled(smBodyLight)`
	margin-bottom: ${gap[12]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}
`;

const Card__ListItemBook = styled(smBodyBook)``;

const Card__Link = styled.div`
	margin-top: auto;
`;

const Card__LinkInner = styled.div`
	margin-top: ${gap[24]};
`;

const Card__AddOnsButton = styled(smBodyRegular)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	align-self: flex-start;
	margin-top: ${gap[24]};
	padding: ${gap[4]} ${gap[0]};
	background-color: transparent;
	border-top: none;
	border-right: none;
	border-bottom: 2px solid var(--primaryAccent);
	border-left: none;
	color: var(--noir);
	appearance: none;
	cursor: pointer;
	transition: color 0.3s ease-in-out;

	&:focus {
		color: var(--noir);
	}

	&[aria-expanded='true'] svg {
		transform: rotate(180deg);
	}

	svg {
		margin-left: ${gap[8]};
		transition: transform 0.3s ease-in-out;
	}

	svg path {
		fill: currentColor;
	}

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${gap[44]};

			svg {
				width: 32px;
				height: 32px;
			}
		`
	)}

	${hover(
		() => css`
			&:hover {
				color: var(--black);
			}
		`
	)}
`;

const Card__AddOnsList = styled(BulletList)`
	margin-top: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-top: ${gap[32]};
		`
	)}
`;

const Card__AddOnsListItem = styled(Card__ListItem)``;

export default {
	Card__Wrapper,
	Card,
	Card__Content,
	Card__Title,
	Card__SubText,
	Card__Copy,
	Card__Location,
	Card__List,
	Card__ListItem,
	Card__ListItemBook,
	Card__Link,
	Card__LinkInner,
	Card__AddOnsButton,
	Card__AddOnsList,
	Card__AddOnsListItem,
};
